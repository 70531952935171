footer {
    background: $primary;
    height: 390px;
    padding: 5rem 0;
    // overflow: hidden

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        height: 100%;
        padding: 0; }

    &.energie {
        background: $primary--yellow;
        position: relative;
        margin-top: 100px;
        height: 320px;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            height: 100%; }

        @media only screen and (max-width: 648px) {
            margin-top: 0; }

        &::after {
            content: "";
            background: url(/wp-content/themes/bergauf/assets/img/layout/footer-cover.svg);
            position: absolute;
            left: 0;
            width: 100%;
            height: 128px;
            z-index: 900;
            background-position: center top;
            top: -98px;
            display: block;
            background-repeat: no-repeat !important;

            @media only screen and (max-width: 1753px) {
                top: -88px; }

            @media only screen and (max-width: 1575px) {
                top: -78px; }

            @media only screen and (max-width: 1394px) {
                top: -68px; }

            @media only screen and (max-width: 1209px) {
                top: -60px; }

            @media only screen and (max-width: 1066px) {
                top: -55px; }

            @media only screen and (max-width: 975px) {
                top: -50px; }

            @media only screen and (max-width: 886px) {
                top: -45px; }

            @media only screen and (max-width: 797px) {
                top: -40px; }

            @media only screen and (max-width: 708px) {
                top: -35px; }

            @media only screen and (max-width: 648px) {
                display: none; } }

        p, a {
            color: $dark; }

        a:hover {
            color: #636165; }

        .footer__social-media-icon {
            filter: $filter-dark;

            &:hover {
                filter: invert(38%) sepia(6%) saturate(245%) hue-rotate(227deg) brightness(95%) contrast(88%); } } }

    // .footer__policy
    //     // @media only screen and (max-width: #{map-get($breakpoints, m)})
    //     //     margin-bottom: 5rem

    .footer__opening, .footer__policy {
        img {
            width: 12rem; } }

    .footer__wrapper {
        display: flex;
        justify-content: space-between;
        max-width: $boxed;
        row-gap: 4rem;
        margin: 0 auto;
        flex-wrap: wrap;
        padding: 6 * $base-width 2 * $base-width; }

    .footer__social-media {
        gap: 10px; }

    .footer--heading {
        color: $light;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        margin: 0; }

    .footer__social-media {
        display: flex;

        a {
            margin: 0; }

        &-facebook {
            display: flex;
            gap: 1rem; } }

    .footer__social-media-icon {
        height: 3rem;
        transition: $transition;
        filter: invert(99%) sepia(3%) saturate(0%) hue-rotate(183deg) brightness(115%) contrast(100%);

        &:hover {
            filter: $filter-tertiary; } }

    .footer__opening {
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            display: flex;
            justify-content: center;
            flex-direction: column; }
        p {
            padding: 0;
            margin: 0; }

        .footer--heading {
            padding-bottom: 2.4rem; } }

    .footer__contact-phone {
        display: flex;

        p:first-child {
            font-weight: 500;
            padding-bottom: 0; }

        p:nth-child(2) {
            margin-left: 0.5rem;
            padding-bottom: 0; } }

    .footer__contact-fax {
        display: flex;

        p:first-child {
            font-weight: 500; }

        p:nth-child(2) {
            margin-left: 0.5rem; } }

    tr {
        vertical-align: top; }

    td:nth-child(odd) {
        p {
            font-weight: 500; } }

    td:nth-child(even) {
        padding-left: 1rem;
        padding-bottom: 0; }

    p {
        color: $light; }

    li {
        list-style: none; }

    a {
        color: $light;
        font-weight: 300;

        &:hover {
            color: $tertiary; } }

    .footer__contact {
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            width: 50%; }
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            width: 100%;
 } }            // margin-bottom: 50px

    .footer__opening {
        // @media only screen and (max-width: #{map-get($breakpoints, m)})
        //     width: 50%
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            width: 100%;
 } }            // margin-bottom: 50px

    .footer__policy {
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            width: 100%;
 } } }            // margin-bottom: 50px
