/*Variables: Accordion * */
$accordion-color-1: $secondary;
$accordion-color-2: $primary;
$accordion-color-3: #fff;
$filter-color-1: invert(12%) sepia(25%) saturate(3048%) hue-rotate(135deg) brightness(96%) contrast(102%);
$filter-color-3: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);

.accordion-wrapper {
  background-color: #f9f9f9; }

.block-accordion {
  max-width: $boxed;
  padding: 6 * $base-width 2 * $base-width;
  margin: 0 auto;

  h2 {
    margin-bottom: 4.6rem;
    display: flex;
    justify-content: center; }

  h3 {
    width: 90%; }

  .accordion-text {
    margin-bottom: 50px; }

  .accordion {
    .accordion-heading {
      padding: 25px;
      background-color: transparent;
      transition: $transition;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ededed;
      margin-bottom: 2 * $base-width;
      h3, a {
        color: $accordion-color-1;
        transition: $transition; }
      .accordion-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .accordion-icon {
          width: 25px;
          height: 25px;
          transition: $transition;
          filter: $filter-color-1; } }
      &:hover {
        background-color: $accordion-color-2;
        transition: $transition;
        cursor: pointer;
        h3, a {
          color: $accordion-color-3;
          transition: $transition; }
        .accordion-icon {
          transition: $transition;
          filter: $filter-color-3; } } }

    .accordion-heading-active {
      background-color: $accordion-color-2;
      h3, a {
        color: $accordion-color-3;
        transition: $transition; }
      .accordion-icon-wrapper {
        .accordion-icon {
          transform: rotate(180deg);
          transition: $transition;
          filter: $filter-color-3; } } }

    .accordion-body {
      padding: 25px;
      overflow: hidden;

      ul {
        margin-left: 20px; } } } }

