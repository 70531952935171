.block.block-benefits {
    max-width: $boxed;
    margin: 0 auto;
    padding: 8 * $base-width 2 * $base-width;
    position: relative;
    z-index: 2;
    // @media only screen and (min-width: #{map-get($breakpoints, xl)})
    //     padding-top: 120px
    //     padding-bottom: 150px
    // @media only screen and (min-width: #{map-get($breakpoints, xxxl)})
    //     padding-top: 150px
    //     padding-bottom: 250px
    svg.dots {
        position: absolute;
        transform: rotate(178deg);
        left: -80px;
        z-index: -1;
        bottom: 100px;
        @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
            bottom: 150px; }
        @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
            bottom: 250px; } }
    .text-wrapper {
        max-width: 100%;
        // @media only screen and (min-width: #{map-get($breakpoints, xl)})
        //     max-width: 750px
        // @media only screen and (max-width: #{map-get($breakpoints, xl)})
        //     h2
        //         text-align: center
        .checkpoint {
            opacity: 1;
            display: flex;
            column-gap: 30px;

            p {
                padding-left: 0;
                width: 90%; }
            // display: flex
 } }            // column-gap: 10px
    // .media-wrapper
    //     position: absolute
    //     width: 100%
    //     top: 0
    //     max-height: 1005px
    //     height: 100%
    //     right: -330px
    //     opacity: 0
    //     z-index: -1
    //     svg
    //         max-width: 100%
    //         max-height: 100%

    //     @media only screen and (min-width: #{map-get($breakpoints, xl)})
    //         max-width: 750px
    //         right: -290px
    //         opacity: 1
    //     @media only screen and (min-width: #{map-get($breakpoints, xxl)})
    //         max-width: 950px
    //     @media only screen and (min-width: #{map-get($breakpoints, xxxl)})
    //         max-width: 1080px

    // &.in-view

    //  // .text-wrapper
    //  //     .checkpoint:nth-child(1)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .35s

    //  //     .checkpoint:nth-child(2)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .45s


    //  //     .checkpoint:nth-child(3)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .55s


    //  //     .checkpoint:nth-child(4)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .65s


    //  //     .checkpoint:nth-child(5)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .75s


    //  //     .checkpoint:nth-child(6)
    //  //         animation: 200ms slide-in-left ease forwards
    //  //         animation-delay: .85s


    //  // @keyframes slide-in-left
    //  //     from
    //  //         margin-left: -25%
    //  //         margin-right: 25%
    //  //         opacity: 0

    //  //     to
    //  //         margin-left: 0
    //  //         margin-right: 0
 }    //  //         opacity: 1
