.block-gallery-sort {
    padding: 8 * $base-width 2 * $base-width;
    max-width: $boxed;
    margin: 0 auto;

    .gallery-images-row {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .fancybox {
            flex: 0 0 23%;

            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex: 1 0 30%; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; }

            picture {
                padding-top: 300px;
                position: relative;
                display: block;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover; } }

            .caption {
                color: $dark;
                margin-top: 10px; } } } }


.fancybox-caption {
    font-size: 25px;

    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 20px; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 16px; } }

.block-text-columns + .block-gallery-sort {
    padding-top: 0; }
