* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

html {
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 1920px;
  margin: 0 auto;
  scroll-behavior: smooth; }

body {
  -webkit-text-size-adjust: none;
  font-size: 1.8rem;
  color: $dark;
  line-height: 160%;
  font-weight: 400;
  font-family: $font-default;
  overflow-x: hidden;
  top: 0 !important;
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba($dark,0.2); }


img {
  display: block;
  max-width: 100%; }

main {
  padding-top: 110px; }

.energie {
  main {
    padding-top: 96px; } }

main,
.block {
  position: relative; }

// #google_translate_element
//   height: 35px
//   margin-bottom: 2 * $base-width

//   @media only screen and (max-width: #{map-get($breakpoints, l)})
//     width: 100%

//   .goog-te-gadget
//     color: $light
//     font-size: 0px

//     .goog-te-combo
//       font-family: $font-default
//       text-decoration: none
//       padding: 4px
//       margin: 0
//       border-radius: 0
//       border: 1px solid #eaeaea

//       &:focus-visible
//         outline: 0

//   .goog-logo-link
//     display: none

// .goog-te-banner-frame.skiptranslate
//   display: none
