.block.block-trennlinie {
    padding: 2 * $base-width;

    .divider {
        width: $boxed;
        margin: 0 auto;
        height: 1px;
        padding: 0;

        &.color-blue {
            background-color: $secondary; }

        &.color-grey {
            background-color: #dbdbdb; } } }
