/*Variables: text * */
.block.block-services {
  position: relative;
  max-width: $boxed;
  padding: 6 * $base-width 2 * $base-width;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 80px
  // padding-bottom: 100px
  @media only screen and (min-width: #{map-get($breakpoints, s)}) {
    padding-bottom: 120px; }
  @media only screen and (min-width: #{map-get($breakpoints, m)}) {
    padding-bottom: 150px; }

  @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
    padding-bottom: 250px;
    padding-top: 100px; }

  .btn {
    margin-top: 10px; }

  svg.dotted-bg , svg.green-bg {
    position: absolute;
    z-index: -1;
    max-width: 100%;
    top: 0;
    max-height: 100%; }

  svg.green-bg {
    right: -100px;
    max-height: 1240px; }

  svg.dotted-bg {
    right: 0;
    max-height: 789px {



 } }       //ANIMATION
  // &.in-view
  //   .text-icons__text-item:nth-child(1)
  //     animation: slide-in 100ms ease-in-out forwards
  //     animation-delay: .35s

  //     @keyframes slide-in
  //         0%
  //             margin-left: -50%
  //             margin-right: 50%
  //             opacity: 0

  //         50%
  //             margin-left: -25%
  //             margin-right: 25%
  //             opacity: 0.5

  //         100%
  //             margin-left: 0
  //             margin-right: 0
  //             opacity: 1


  //// .text-icons__text-item:nth-child(2)
  ////   animation: slide-in 100ms ease-in-out forwards
  ////   animation-delay: .45s

  ////   @keyframes slide-in
  ////       0%
  ////           margin-left: -50%
  ////           margin-right: 50%
  ////           opacity: 0

  ////       50%
  ////           margin-left: -25%
  ////           margin-right: 25%
  ////           opacity: 0.5

  ////       100%
  ////           margin-left: 0
  ////           margin-right: 0
  ////           opacity: 1


  //// .text-icons__text-item:nth-child(3)
  ////   animation: slide-in 100ms ease-in-out forwards
  ////   animation-delay: .55s

  ////   @keyframes slide-in
  ////       0%
  ////           margin-left: -50%
  ////           margin-right: 50%
  ////           opacity: 0

  ////       50%
  ////           margin-left: -25%
  ////           margin-right: 25%
  ////           opacity: 0.5

  ////       100%
  ////           margin-left: 0
  ////           margin-right: 0
  ////           opacity: 1200ms


  //// .text-icons__text-item:nth-child(4)
  ////   animation: slide-in 100ms ease-in-out forwards
  ////   animation-delay: .65s

  ////   @keyframes slide-in
  ////       0%
  ////           margin-left: -50%
  ////           margin-right: 50%
  ////           opacity: 0

  ////       50%
  ////           margin-left: -25%
  ////           margin-right: 25%
  ////           opacity: 0.5

  ////       100%
  ////           margin-left: 0
  ////           margin-right: 0
  ////           opacity: 1


  //// .text-icons__text-item:nth-child(5)
  ////   animation: slide-in 100ms ease-in-out forwards
  ////   animation-delay: .75s

  ////   @keyframes slide-in
  ////       0%
  ////           margin-left: -50%
  ////           margin-right: 50%
  ////           opacity: 0

  ////       50%
  ////           margin-left: -25%
  ////           margin-right: 25%
  ////           opacity: 0.5

  ////       100%
  ////           margin-left: 0
  ////           margin-right: 0
  ////           opacity: 1

  //// .text-icons__text-item:nth-child(6)
  ////   animation: slide-in 100ms ease-in-out forwards
  ////   animation-delay: .85s

  ////   @keyframes slide-in
  ////       0%
  ////           margin-left: -50%
  ////           margin-right: 50%
  ////           opacity: 0

  ////       50%
  ////           margin-left: -25%
  ////           margin-right: 25%
  ////           opacity: 0.5

  ////       100%
  ////           margin-left: 0
  ////           margin-right: 0
  ////           opacity: 1


  // .text-icons
  //   max-width: $boxed
  //   margin: 0 auto
  //   .hl-text-icons
  //     margin: 0 auto
  //     text-align: center
  //     @media only screen and (min-width: #{map-get($breakpoints, xs)})
  //       max-width: 795px
  //       margin: 0
  //       // text-align: left
  //     .headline-wrapper
  //         @media only screen and (min-width: #{map-get($breakpoints, l)})
  //           max-width: 605px
  //         h2, .h2
  //           margin-bottom: 6.25*$base-width
  //     p
  //       padding-bottom: 0

  .hl-text-icons {
    text-align: center; }

  .text-icons__text-item-wrapper {
    width: 100%; }

  .text-icons__text-item {
    max-width: 480px;
    width: 100%;
    padding-bottom: 18.75*$base-width;
    opacity: 1;
    flex: 0 0 100%;
    display: block;
    padding-bottom: 10*$base-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 45px;
      filter: $filter-light; }

    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
      flex: 0 0 50%; }
    @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
      flex: 0 0 33.33%; }


    // &-content
    //   margin-left: 3rem
    //   @media only screen and (max-width: #{map-get($breakpoints, s)})
    //     margin-left: 0
    &-wrapper {
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 13.75*$base-width;
      @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        justify-content: space-around; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 6.25*$base-width; }
      .text-icons__text-item-content {
        padding-top: 4.375*$base-width;
        text-align: center;
        width: 80%;

        // @media only screen and (min-width: #{map-get($breakpoints, xs)})
        //   text-align: left
        h4, .h4 text-icons__text-item-wrapper {
          font-size: 2.4rem;
          padding-bottom: 2.5*$base-width;
          height: 60px; }

        p {
          line-height: 30px;
          padding-bottom: 0; } }


      .text-icons__text-item-icon {
        max-width: 88px;
        max-height: 88px;
        width: 88px;
        height: 88px;
        background: linear-gradient(to right bottom, #72ba4e, #7dc15c, #89c86a, #94cf77);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
          margin: 0 auto; }
        svg {
          max-height: 49px;
          max-width: 49px;
          width:: 100% {}
          fill: #fff; } }

 } }      // filter: $filter-primary

  .text-icons-grid {
    overflow: visible; } }



