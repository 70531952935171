.block.block-beitragsliste {
    max-width: $small;
    margin: 0 auto;
    padding: 8 * $base-width 2 * $base-width;

    .text-content {
        text-align: center; }

    .blog-list__item-wrapper {
        display: flex;
        flex-direction: column;
        padding: 2 * $base-width 0;

        .post-item {

            &__inner {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                background-color: $light;
                color: $dark;
                // padding: 3 * $base-width
                margin-bottom: 6 * $base-width;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                transition: $transition;

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    margin-bottom: 4 * $base-width; }

                &:hover, &:focus, &:active {
                    text-decoration: none;

                    .btn-link {
                        color: $secondary;
                        border-color: $secondary; } }

                > div {
                    flex: 0 0 70%;
                    width: 70%;
                    padding: 3 * $base-width;

                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        width: 100%;
                        flex: 0 0 100%;
                        padding: 3 * $base-width 3 * $base-width 5 * $base-width 3 * $base-width; } }

                picture {
                    padding-top: 260px;
                    position: relative;
                    flex: 0 0 30%;
                    width: 30%;

                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        width: 100%;
                        flex: 0 0 100%; } }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; }

                .btn-link {
                    color: $primary; } }

            &:last-child .post-item__inner {
                margin-bottom: 0; }

            &__inner-top {

                h2:after {
                    margin-left: 0; } }

            &__excerpt {
                padding-bottom: 2*$base-width;
                color: $dark; } } }

    .list-pagination {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 10*$base-width;

        @media (min-width: #{map-get($breakpoints, l)}) {
            margin-top: 2*$base-width; }

        @media (min-width: #{map-get($breakpoints, xxxl)}) {
            max-width: 1680px;
            width: 100%;
            margin-right: auto;
            margin-left: auto; }

        span, a {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            min-width: 8*$base-width;
            min-height: 8*$base-width;
            padding: $base-width 2*$base-width;
            background: transparent;
            color: $dark;
            font-size: 1.5rem;
            line-height: 1.6;
            text-decoration: none;
            text-align: center;
            border-radius: 100%;

            @media (min-width: #{map-get($breakpoints, s)}) {
                padding: 2*$base-width 3*$base-width;
                font-size: 1.8rem;
                line-height: 1.2;

                &:not(.current):not(.next):not(.prev) {
                    display: none; } }

            &.current, &:hover, &:focus {
                background: $primary;
                color: $light;
                text-decoration: none; }

            &.dots {
                min-width: unset;
                margin-right: $base-width;
                padding: 0;
                cursor: default;
                pointer-events: none;

                &.current, &:hover, &:focus {
                    background: transparent;
                    color: $dark; } }

            &.next, &.prev {
                i {
                    transform: translateY(2px); } } }


        span + span, span + a, a + span, a + a {
            margin-left: $base-width; } } }
