.news-wrapper {
    max-width: $boxed;
    margin: 0 auto;
    padding: 8 * $base-width 2 * $base-width;

    .owl-carousel {
        width: 100% !important;

        .owl-item {
            height: 60rem;
            padding: 2.2rem;

            .owl-item-inner {
                height: 100%;
                box-shadow: 0px 0px 1.6rem 0px rgba(0, 0, 0, 0.08);
                transition: $transition;

                &:hover {
                    transform: scale(1.02); }

                .panel {
                    display: flex;
                    flex-direction: column;

                    .imageholder {
                        position: relative;
                        padding-top: 68%;

                        img {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover; } }

                    .caption-teaser {
                        padding: 3rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        .divider {
                            background-color: $primary;
                            width: 100%;
                            height: 1px; } } } } }


        .owl-nav {
            .owl-prev,
            .owl-next {
                height: 3rem;
                width: 3rem; }

            .owl-next {
                transform: rotate(180deg); } } } }
