/*Variables: Hero Slider* */

@keyframes slideContent {
  0% {
    transform: translateX(-65rem);
    opacity: 0; }

  100% {
    transform: translateX(0);
    opacity: 1; } }

.block.block-slider-hero {
  max-width: $max;
  padding: 0 10 * $base-width 2 * $base-width;

  @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
    padding: 0; } }

.owl-carousel:not(.owl-loaded) {
  display: block;
  opacity: 0;
  height: 70vh;
  overflow: hidden; }

.owl-carousel {
  opacity: 1;
  transition: $transition; }

.hero-slider {
  // margin-bottom: 15rem

  // @media only screen and (min-width: #{map-get($breakpoints, m)}) and (max-width: #{map-get($breakpoints, xl)})
  //   margin-bottom: 25rem

  // .global-padding
  //   @media only screen and (max-width: #{map-get($breakpoints, m)})
  //     padding: 0

  &__button {
    margin-top: 4rem;

    @media only screen and (min-width: #{map-get($breakpoints, xs)}) and (max-width: #{map-get($breakpoints, l)}) {
      margin-top: 2rem; } }

  &__content {
    z-index: 2;
    color: $light;
    position: absolute;
    opacity: 0;
    width: 60%;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
      p {
        font-size: 1.4rem; } }

    @media only screen and (min-width: #{map-get($breakpoints, m)}) and (max-width: #{map-get($breakpoints, l)}) {
      p {
        font-size: 1.6rem; } }

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      margin-left: 3rem; }

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
      margin-left: 10rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
      margin-left: 25rem; }

    // @media only screen and (min-width: #{map-get($breakpoints, xxl)})
    //   margin-left: 25rem

    .button {
      margin-top: 3rem; }

    h2 {
      color: $light;
      margin-bottom: 2rem;
      font-weight: 500;
      position: relative;
      font-size: 3.8rem;
      text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.8rem;
      // border-bottom: 5px solid #000

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2.5rem;
        margin-bottom: 1rem; }

      @media only screen and (min-width: #{map-get($breakpoints, m)}) and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 3.2rem;
        margin-bottom: 1rem; } }

    // h2::before
    //   content: ''
    //   position: absolute
    //   height: 10px
    //   width: 200px
    //   background-color: $light
 }    //   bottom: -20px

  &__item-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      object-fit: cover;

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        height: 30vh; }

      @media only screen and (min-width: #{map-get($breakpoints, m)}) {
        height: 40vh; }

      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        height: 50vh; }

      @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        height: 60vh; } } }

  .active {
    .hero-slider__item-wrapper {
      img {
        transform: scale(1);
        transition: transform 10s; } }

    .hero-slider__content {
      animation: slideContent 0.4s ease-in-out 1.5s forwards;
      width: 40%;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        width: 70%; }
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        margin-left: 2rem;
        width: 90%; } } }

  &-overlay {
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: black;
    position: absolute;
    opacity: 0.4;
    z-index: 1; }

  .owl-nav {
    bottom: 50%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
      bottom: 12%;
      right: 2%;
      column-gap: 1rem;
      justify-content: flex-end; }

    .owl-prev, .owl-next {
      transition: $transition;
      height: 5rem;
      width: 5rem;
      border-radius: 10rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $light !important;

      img {
        transition: $transition;
        filter: $filter-primary;
        width: 2rem;

        // &:hover
 } }        //   filter: $filter-primary

    .owl-prev {
      transform: scale(-1);

      @media only screen and (min-width: #{map-get($breakpoints, m)}) {
        margin-left: 2rem; }

      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        margin-left: -3rem; } }

    .owl-next {
      @media only screen and (min-width: #{map-get($breakpoints, m)}) {
        margin-right: 2rem; }

      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        margin-right: -3rem; } } } }
