.btn {
  padding: 2 * $base-width 4 * $base-width;
  transition: $transition;

  &.btn-primary {
    background-color: $primary;
    color: $light;

    &:hover {
        background-color: $secondary; } }

  &.btn-link {
    padding: $base-width 0;
    border-bottom: 2px solid $primary;

    &:hover {
      border-color: $secondary; } } }
