.block.block-text-media {
    background-color: #f9f9f9;

    &.grau {
        background-color: #f9f9f9; }

    &.weiss {
        background-color: #ffffff; }

    .text-media-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8 * $base-width 2 * $base-width;
        max-width: $boxed;
        margin: 0 auto;

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex-wrap: wrap-reverse; }

        &.img-right {
            flex-direction: row; }

        &.img-left {
            flex-direction: row-reverse; }

        &.energie {
            .btn {
                background-color: $primary--yellow;
                color: $dark;

                &:hover {
                    background-color: $primary--yellow-darker; } } }

        .text-wrapper {
            flex: 0 0 45%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%;
                margin-top: 4 * $base-width; }

            ol {
                padding-left: 2rem !important;
                margin-bottom: 2rem !important; }

            h1, .h1, h3, .h3, h2, .h2 {
                margin-bottom: 3 * $base-width; }

            .btn {
                margin-top: 3 * $base-width; } }

        .img-mobile {
            display: none;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: block; } }

        .img-desktop {
            display: block;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; } }

        .media-wrapper {
            flex: 0 0 50%;
            position: relative;
            padding-top: 44%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%;
                padding-top: 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 60%; }

            img, iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; } } } }
