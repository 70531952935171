.block.block-beitragsteaser {
    max-width: $boxed;
    margin: 0 auto;
    padding: 12 * $base-width 2 * $base-width;

    .text-content {
        text-align: center; }

    .blog-list__item-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5%;
        padding: 6*$base-width 0 2*$base-width;

        .post-item {
            width: 30%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                width: 100%;
                margin-bottom: 4 * $base-width; }

            .post-item__inner {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

                &:hover {
                    .btn {
                        border-color: $secondary; } }

                picture {
                    padding-top: 260px;
                    position: relative;
                    display: block;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover; } }


                .post-item__inner-top {
                    // box-shadow: 0 0 10px rgba($dark,0.3)
                    padding: 3 * $base-width 3 * $base-width 5 * $base-width 3 * $base-width;

                    p {
                        color: $dark; } } } } }

    .button-wrapper {
        margin-top: 4 * $base-width;
        display: flex;
        justify-content: center; } }
