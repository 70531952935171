.block-headimage {
    .image-text-wrapper {
        position: relative;
        padding-top: 550px;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 350px; }

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            margin: 0 60px; }

        @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
            margin: 0 200px; }

        &.energie {
            position: relative;
            padding-top: 700px;

            &::after {
                content: "";
                background: url(/wp-content/themes/bergauf/assets/img/layout/hero-cover.svg);
                position: absolute;
                left: 0;
                width: 100%;
                height: 128px;
                z-index: 1;
                background-position: center top;
                bottom: -48px;
                display: block;
                background-repeat: no-repeat !important;

                @media only screen and (max-width: 1864px) {
                    bottom: -51px; }

                @media only screen and (max-width: 1529px) {
                    bottom: -65px; }

                @media only screen and (max-width: 1153px) {
                    bottom: -76px; }

                @media only screen and (max-width: 950px) {
                    bottom: -85px; }

                @media only screen and (max-width: 784px) {
                    bottom: -93px; }

                @media only screen and (max-width: 648px) {
                    display: none; } } }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%; }

        .text {
            position: absolute;
            top: 50%;
            line-height: 190%;
            transform: translateY(-50%);
            max-width: $boxed;
            margin: 0 auto;
            left: 0;
            right: 0;
            z-index: 2;
            color: white;
            padding: 4 * $base-width 2 * $base-width;

            // @media only screen and (min-width: #{map-get($breakpoints, xxl)})
            //     max-width: $small

            // @media only screen and (min-width: #{map-get($breakpoints, xxxl)})
            //     max-width: $boxed

            .btn {
                margin-top: 40px; }

            p {
                font-size: 2.4rem; }

            h1 {
                color: $light;
                max-width: 50%;
                // margin: 0 auto
                // text-align: left
                font-size: 4rem;

                position: relativeadd;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    font-size: 2.4rem;
                    max-width: 100%; } } } }

    .image-text-wrapper-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.9;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(24,24,24,1) 12%, rgba(255,255,255,0) 100%); } }

.home {
    .block-headimage {
        .image-text-wrapper {
            position: relative;
            padding-top: 700px;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 80vh; } } } }
