/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  padding: 12 * $base-width 2 * $base-width;
  max-width: $boxed;
  margin: 0 auto;

  &.energie {
    button {
      background-color: $primary--yellow;
      color: $dark;

      &:hover {
        background-color: $primary--yellow-darker; } }

    a {
      color: $primary--yellow-darker; }

    .formular-wrapper {
      display: block; }

    .contact {
      font-size: 2.4rem;
      font-weight: 600; } }

  .formular-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h1 {
      flex: 0 0 100%; }

    .contact, .text {
      flex: 0 0 48%;
      margin-bottom: 8 * $base-width;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 100%;
        margin-bottom: 4 * $base-width; } } } }

.form-2 {
  .form-2-col {
    margin-top: 45px; }

  select {
    margin-bottom: 2.5rem;
    padding: 1rem; } }

.form {
  flex: 0 0 100%;

  &.easy-form {
    margin-top: 4rem;

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      margin-top: 2rem; }

    textarea {
      min-height: 229px; } }

  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .form-1, .form-2 {
      flex: 0 0 48%;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 100%; } } }

  h3 {
    margin-bottom: 5rem; }

  button {
    width: 20rem;
    text-transform: uppercase;
    border: 0;
    cursor: pointer; }

  a {
    text-transform: inherit;
    letter-spacing: 0;
    font-weight: 300; }

  label {
    font-weight: 300;
    letter-spacing: 0.05rem; }

  input {
    margin-bottom: 2.5rem; }

  textarea {
    margin-bottom: 3rem;
    min-height: 13rem; }

  .form-grid {
    padding: 5rem; }

  .form-field-group {
    display: flex;
    flex-direction: column;

    input {
      height: 4rem; } }

  &__date-selection {
    margin-bottom: 4rem; } }

.form-contact-container {
  padding: 5rem;
  .form-contact-container-info {
    margin-top: 2rem; }
  .form-contact-container-item {
    display: flex;
    align-items: flex-start;
    img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
      margin-top: 0.5rem; }
    p {
      margin: 0;
      padding: 0; } } }

.form {
  #form-dsvg-container {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 2rem;

    input {
      margin-bottom: 0; } }

  #email-confirm {
    position: absolute;
    left: -20000px; } }

.form {
  .form-success,
  .form-error {
    width: 100%;
    border: 2px solid $error;
    background-color: rgba($error,.25);
    padding: 3rem;
    margin-bottom: 3rem; }

  .form-success {
    border: 2px solid $success;
    background-color: rgba($success,.25); }

  .error {
    border-color: $error;
    background-color: rgba($error,.25); } }
