.detail-blog {
  .detail-head-image {
    position: relative;
    padding-top: 600px;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
      padding-top: 400px; }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; } }

  .detail-text {
    max-width: $small;
    margin: 0 auto;
    padding: 8 * $base-width 2 * $base-width;

    ul {
      padding-left: 5 * $base-width; } }

  .detail-gallery {
    max-width: $boxed;
    margin: 0 auto;
    padding: 2 * $base-width;
    gap: 2 * $base-width;
    display: flex;
    flex-wrap: wrap;

    picture {
      position: relative;
      display: block;
      flex: 1 0 30%;
      padding-top: 350px;

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        flex: 1 0 50%; }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        flex: 1 0 100%; }

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; } } } }
