.block.block-download {
    max-width: $boxed;
    margin: 0 auto;

    .text-wrapper {
        margin-bottom: 4 * $base-width; }

    .download-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
            flex: 0 0 49%;
            padding: 3 * $base-width 4 * $base-width;
            background-color: $light;
            margin-bottom: 2 * $base-width;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            text-transform: uppercase;
            box-shadow: 0 0 10px rgba($dark, 0.3);

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                padding: 3 * $base-width 2 * $base-width; }

            &:hover {
                background-color: $primary;
                color: white; }

            i {
                font-size: 4rem;
                line-height: 1rem;
                margin-right: 2 * $base-width;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    font-size: 3rem; } } } } }
