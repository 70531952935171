// Base
$base-width: .8rem;

// Fonts
$font-default: 'Nunito Sans';

// General
$dark: #353139;
$light: #ffffff;

// Brand Colors
$primary: #66B340;
$secondary: #629f43;
$tertiary: #c5dcba;

$primary--yellow: #FFD500;
$primary--yellow-darker: #F4CB00;

$filter-primary: invert(64%) sepia(17%) saturate(1062%) hue-rotate(52deg) brightness(96%) contrast(87%);
$filter-light: invert(100%) sepia(100%) saturate(2%) hue-rotate(339deg) brightness(105%) contrast(101%);
$filter-tertiary: invert(94%) sepia(9%) saturate(639%) hue-rotate(49deg) brightness(91%) contrast(91%);
$filter-dark: invert(18%) sepia(3%) saturate(1725%) hue-rotate(227deg) brightness(97%) contrast(93%);

// Others
$divider: rgba($dark, .3);

// Widths
$max: 2560px;
$boxed: 1470px;
$small: 1100px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1500px, "xxxl": 1800px);
