/* Third-Party */
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/buttons';
@import './layout/detail-blog';
@import './layout/error-page';


/* Blocks */
@import '../../blocks/text-media/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/gallery-sort/style';
@import '../../blocks/form/style';
@import '../../blocks/download/style';
@import '../../blocks/accordion/style';
@import '../../blocks/headimage/style';
@import '../../blocks/beitragsteaser/style';
@import '../../blocks/beitragsliste/style';
@import '../../blocks/trennlinie/style';
@import '../../blocks/image/style';
@import '../../blocks/slider-hero/style';
@import '../../blocks/benefits/style';
@import '../../blocks/services/style';
@import '../../blocks/auto-insta-fetch/style';
