.block-image {
    max-width: $boxed;
    margin: 0 auto;
    padding: 6 * $base-width;

    .media-wrapper {
        position: relative;
        padding-top: 40%;


        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: contain; } } }
